const Batch2022 = [
  {
    name: "Dhrubajyoti Kumar",
    post: "Chair",
    img: require("../media/batch2022/dhrubajyoti.png"),
    fb: "https://www.facebook.com/dhrubajyoti.kumar.14",
    insta: "https://www.instagram.com/ne.el3174/",
    linkedin: "https://www.linkedin.com/in/dhrubajyoti-kumar-3828171aa/",
    mail: "",
  },
  // {
  //   name: "Khushi Bansal",
  //   post: "Vice Chair",
  //   img: require("../media/batch2022/Khusi3.png"),
  //   fb: "https://www.facebook.com/khushi.bansal.756",
  //   insta: "https://www.instagram.com/ne.el3174/",
  //   linkedin: "https://www.linkedin.com/in/khushi-bansal-298849177/",
  //   mail: "",
  // },
  {
    name: "Subhangee Shrey",
    post: "Webmaster and Secretary",
    img: require("../media/batch2022/Shubhangee Shrey.png"),
    fb: "https://m.facebook.com/shubhangee.shubhangee.169",
    insta: "https://www.instagram.com/shubhangee_shrey/",
    linkedin: "https://www.linkedin.com/in/shubhangee-8920831b8/",
    mail: "",
  },
  {
    name: "Aman Agarwal",
    post: "Treasurer",
    img: require("../media/batch2022/Aman2nd.jpg"),
    fb: "https://www.facebook.com/aman.agarwal.564813",
    insta: "https://www.instagram.com/aman._agarwal._/",
    linkedin: "https://www.linkedin.com/in/aman-agarwal-42160b184/",
    mail: "",
  },
  {
    name: "Mohit Kochar",
    post: "Secretary",
    img: require("../media/batch2022/Mohit.png"),
    fb: "",
    insta: "",
    linkedin: "https://www.linkedin.com/in/mohit-kochar-2143aa179",
    mail: "",
  },
  {
    name: "Vimal Dubey",
    post: "Convener",
    img: require("../media/batch2022/vimal.png"),
    fb: "https://www.facebook.com/vimalkumar.dubey.982",
    insta: "https://www.instagram.com/vimal_1922/",
    linkedin: "https://www.linkedin.com/in/vimal-dubey-1661ba1a0/",
    mail: "",
  },
  {
    name: "Pritish Singhal",
    post: "Events Chair",
    img: require("../media/batch2022/Pritish.png"),
    fb: "https://www.facebook.com/pritish.singhal.5",
    insta: "https://www.instagram.com/pritishsinghal/",
    linkedin: "https://www.linkedin.com/in/pritish-singhal-4a0b29169/",
    mail: "",
  },
  {
    name: "Gagan Gautam",
    post: "Publicity & Outreach Chair",
    img: require("../media/batch2022/gagan2nd.png"),
    fb: "https://www.facebook.com/gagan.gautam.35380399",
    insta: "",
    linkedin: "https://www.linkedin.com/in/gagan-gautam/",
    mail: "",
  },
  {
    name: "Atul Tiwary",
    post: "Logistics Chair",
    img: require("../media/batch2022/Atul.png"),
    fb: "https://www.facebook.com/profile.php?id=100007864380067",
    insta: "https://www.instagram.com/atul3152000/",
    linkedin: "https://www.linkedin.com/in/atul-tiwari-b5376a146/",
    mail: "",
  },
];

export default Batch2022;

