const Batch2025 = [
{
    name: "Anshuman Jha",
    post: "Junior Executive Member",
    img: require("../media/batch2025/Anshuman_Jha.jpg"),
    fb: "https://www.facebook.com/anshuman.jha.0350",
    insta: "https://www.instagram.com/anshuman.jha.0350/?hl=en",
    linkedin: "https://www.linkedin.com/in/anshuman-jha-b72492216",
    mail: "aj.21u10294@btech.nitdgp.ac.in",
  },
  {
    name: "Arghya Kamal Das",
    post: "Junior Executive Member",
    img: require("../media/batch2025/ArghyaKamalDas.jpg"),
    fb: "https://www.facebook.com/arghyakamal.das.39",
    insta: "",
    linkedin: "https://www.linkedin.com/mwlite/in/arghya-kamal-das-aa8173235",
    mail: "techb456@gmail.com",
  },
  {
    name: "Balivada sri vamsi",
    post: "Junior Executive Member",
    img: require("../media/batch2025/Balivada_sri_Vamsi.jpg"),
    fb: "https://www.facebook.com/profile.php?id=100075715284752",
    insta: "https://www.instagram.com/vamsibalivada25/",
    linkedin: "https://www.linkedin.com/in/balivada-vamsi-5299aa228/",
    mail: "vamsibalivada25@gmail.com",
  },
  {
    name: "Bhupinder Kumar",
    post: "Junior Executive Member",
    img: require("../media/batch2025/BHUPINDER_KUMAR.jpg"),
    fb: "https://www.facebook.com/profile.php?id=100064747202819",
    insta: "https://www.instagram.com/bhupinderkumar1905/",
    linkedin: "https://www.linkedin.com/in/bhupinder-kumar-288722239/",
    mail: "bk.21u10911@btech.nitdgp.ac.in",
  },
  {
    name: "Debashis Panigrahi",
    post: "Junior Executive Member",
    img: require("../media/batch2025/Debashis_Panigrahi.jpg"),
    fb: "https://www.facebook.com/profile.php?id=100081054785707",
    insta: "https://www.instagram.com/debashis_p_03/",
    linkedin: "https://www.linkedin.com/in/debashis-panigrahi-25b8b2162/",
    mail: "debashis2003panigrahi@gmail.com",
  },
  {
    name: "Kirtika Gautam",
    post: "Junior Executive Member",
    img: require("../media/batch2025/Kirtika_Gautam.jpg"),
    fb: "https://www.facebook.com/profile.php?id=100077273692129",
    insta: "https://www.instagram.com/_kirtikaa001/",
    linkedin: "https://www.linkedin.com/in/kirtika-gautam-a044ab22b",
    mail: "kirgautam25@gmail.com",
  },
  {
    name: "Md Sahanawaj Karim",
    post: "Junior Executive Member",
    img: require("../media/batch2025/SahanawajKarim.jpg"),
    fb: "https://m.facebook.com/100026568824378/",
    insta: "https://www.instagram.com/sahanawajkarim/",
    linkedin: "https://www.linkedin.com/mwlite/in/sahanawaj-karim-074882142",
    mail: "sahanawaj121@gmail.com",
  },
  {
    name: "Mohit Kumar",
    post: "Junior Executive Member",
    img: require("../media/batch2025/mohit_kumar.jpg"),
    fb: "https://m.facebook.com/mohit.aryaa.1",
    insta: "https://www.instagram.com/i_m0hit_/?hl=en",
    linkedin: "https://www.linkedin.com/mwlite/in/mohit-kumar-1678541b7",
    mail: "mohitkumar91614@gmail.com",
  },
  {
    name: "Raja Paul",
    post: "Junior Executive Member",
    img: require("../media/batch2025/RAJA_PAUL.jpeg"),
    fb: "https://www.facebook.com/profile.php?id=100013355171895",
    insta: "https://www.instagram.com/mr_raja_paul/?hl=en",
    linkedin: "https://www.linkedin.com/in/raja-paul-57461b229/",
    mail: "mrpaulraja1@gmail.com",
  },
  {
    name: "Sneha Ghosh",
    post: "Junior Executive Member",
    img: require("../media/batch2025/SnehaGhosh.jpg"),
    fb: "https://www.facebook.com/profile.php?id=100070327063168",
    insta: "",
    linkedin: "https://www.linkedin.com/in/sneha-g-521019230",
    mail: "Sg.21u10904@btech.nitdgp.ac.in",
  },
  {
    name: "Sukamal Samanta",
    post: "Junior Executive Member",
    img: require("../media/batch2025/sukamalsamanta.jpg"),
    fb: "",
    insta: "",
    linkedin: "https://www.linkedin.com/mwlite/in/sahanawaj-karim-074882142",
    mail: "sukamalsamanta8967@gmail.com",
  },
  {
    name: "Yashvardhan Singh",
    post: "Junior Executive Member",
    img: require("../media/batch2025/YashvardhanSingh.jpg"),
    fb: "https://www.facebook.com/profile.php?id=100077389422823",
    insta: "https://instagram.com/yashhh1004?igshid=YmMyMTA2M2Y=",
    linkedin: "https://www.linkedin.com/in/yashvardhan-singh-7881b6224",
    mail: "yashsingh4703@gmail.com",
  },
  {
    name: "Yogesh Kumar",
    post: "Junior Executive Member",
    img: require("../media/batch2025/YogeshKumar.jpg"),
    fb: "https://m.facebook.com/100024589936312/",
    insta: "https://www.instagram.com/v_yogiii54/",
    linkedin: "https://www.linkedin.com/mwlite/in/yogesh-kumar-50a6b1233",
    mail: "kumaryogesh2952@gmail.com",
  },
];

export default Batch2025;
