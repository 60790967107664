const Batch2024 = [
{
    name: "Abhishek Anand",
    post: "Senior Executive Member",
    img: require("../media/batch2024/Abhishek anand.jpg"),
    fb: "",
    insta: "",
    linkedin: "https://www.linkedin.com/in/abhishek-anand1012",
    mail: "abhishekabhishek1012@gmail.com",
  },
  {
    name: "Aheli Chakrabarti",
    post: "Senior Executive Member",
    img: require("../media/batch2024/AheliChakrabarti.png"),
    fb: "https://www.facebook.com/aheli.chakrabarti.7/",
    insta: "https://www.instagram.com/shubhangee_shrey/",
    linkedin: "https://www.linkedin.com/in/aheli-chakrabarti-b68928208/",
    mail: "",
  },
  {
    name: "Alik Ghosh",
    post: "Senior Executive Member",
    img: require("../media/batch2024/AlikGhosh.png"),
    fb: "https://www.facebook.com/alik.ghosh.547",
    insta: "https://www.instagram.com/alikghosh2002/",
    linkedin: "https://www.linkedin.com/in/alik-ghosh-730836201",
    mail: "",
  },
  {
    name: "Bodhisattwa Das",
    post: "Senior Executive Member",
    img: require("../media/batch2024/Bodhisattwa Das.jpg"),
    fb: "https://m.facebook.com/100006327733627/",
    insta: "https://www.instagram.com/bodhi_das/?hl=en",
    linkedin: "https://www.linkedin.com/mwlite/in/bodhisattwa-das-6b5720212",
    mail: "bodhi140802@gmail.com ",
  },
  {
    name: "Durbar Chakrabarty",
    post: "Senior Executive Member",
    img: require("../media/batch2024/DurbarChakrabarty.png"),
    fb: "https://m.facebook.com/durbar.chakrabarty.7?ref=bookmarks",
    insta: "https://instagram.com/_durbar1204_?utm_medium=copy_link",
    linkedin: "https://www.linkedin.com/mwlite/in/durbar-chakrabarty-867395200",
    mail: "",
  },
  {
    name: "Manjari Jha",
    post: "Senior Executive Member",
    img: require("../media/batch2024/Manjari Jha.png"),
    fb: "https://m.facebook.com/manjari.jha.1420",
    insta: "https://www.instagram.com/_mj1002/",
    linkedin: "https://www.linkedin.com/mwlite/in/manjari-jha-95820a208",
    mail: "",
  },
  // {
  //   name: "Moinak Banerjee",
  //   post: "Senior Executive Member",
  //   img: require("../media/batch2024/Moinak.jpeg"),
  //   fb: "",
  //   insta: "",
  //   linkedin: "https://www.linkedin.com/in/moinak878/",
  //   mail: "",
  // },
  {
    name: "Naveen S G",
    post: "Senior Executive Member",
    img: require("../media/batch2024/NaveenSG.png"),
    fb: "",
    insta: "",
    linkedin: "",
    mail: "",
  },
  {
    name: "Nitin Kushwaha",
    post: "Senior Executive Member",
    img: require("../media/batch2024/NitinKushwaha.png"),
    fb: "https://www.facebook.com/naveen.leo.94",
    insta: "https://instagram.com/naveenleo143?utm_medium=copy_link",
    linkedin: "https://www.linkedin.com/in/naveen-s-g-3a1a72207",
    mail: "",
  },
  {
    name: "Sandeep Sahoo",
    post: "Senior Executive Member",
    img: require("../media/batch2024/SandeepSahoo.png"),
    fb: "https://www.facebook.com/profile.php?id=100060633133122",
    insta: "https://instagram.com/sandeepsahoo_1203?utm_medium=copy_link",
    linkedin: "https://www.linkedin.com/in/sandeep-sahoo-91a726200",
    mail: "",
  },
  {
    name: "Shaily Shekhar",
    post: "Senior Executive Member",
    img: require("../media/batch2024/ShailyShekhar.png"),
    fb: "https://www.facebook.com/shaily.shekhar.5/",
    insta: "https://www.instagram.com/shellz149?r=nametag",
    linkedin: "https://www.linkedin.com/in/shaily-shekhar-993135208/",
    mail: "",
  },
  {
    name: "Sreemoyee Sadhukhan",
    post: "Senior Executive Member",
    img: require("../media/batch2024/SreemoyeeSadhukhan.png"),
    fb: "https://www.facebook.com/sreemoyee.sadhukhan",
    insta: "https://www.instagram.com/sreemoyeesadhukhan2001/",
    linkedin: "https://www.linkedin.com/in/sreemoyee-sadhukhan-59b3b81b5",
    mail: "",
  },
  {
    name: "Udayan Mukherjee",
    post: "Senior Executive Member",
    img: require("../media/batch2024/Udayan Mukherjee.jpeg"),
    fb: "https://www.facebook.com/vebz.bonvlifskff.9/",
    insta: "https://www.instagram.com/socialsniper20/",
    linkedin: "linkedin.com/in/udayan-mukherjee-a12358200",
    mail: "",
  },
  {
    name: "Varandeep Sahota",
    post: "Senior Executive Member",
    img: require("../media/batch2024/VarandeepSahota.png"),
    fb: "https://www.facebook.com/varandeep.sahota",
    insta: "https://www.instagram.com/varandeep03/",
    linkedin: "https://www.linkedin.com/in/varandeep-sahota-12a054208",
    mail: "",
  },
];

export default Batch2024;
