const Batch2023 = [
   {
      name: "Santam Roy Choudhury",
      post: "Chair",
      img: require("../media/batch2023/Santam Roy Choudhury.png"),
      fb: "https://www.facebook.com/santam.roychoudhury/",
      insta: "https://www.instagram.com/virtual_ghost_404/",
      linkedin: "https://www.linkedin.com/in/amankumar1402/",
      mail: "",
   },
   {
      name: "Payel Bose",
      post: "Vice Chair",
      img: require("../media/batch2023/Payel1st.png"),
      fb: "https://www.facebook.com/payel.bose.official",
      insta: "https://www.instagram.com/whimsical_viewfinder/",
      linkedin: "https://www.linkedin.com/in/payel-bose-124842209/",
      mail: "",
   },
   {
      name: "Aman Kumar",
      post: "Genral Secretary",
      img: require("../media/batch2023/Aman_Kumar2.png"),
      fb: "https://www.facebook.com/profile.php?id=100037520656634",
      insta: "https://www.instagram.com/ak14aman/",
      linkedin: "https://www.linkedin.com/in/amankumar1402/",
      mail: "",
   },
   {
      name: "Vaishali Barua",
      post: "Treasurer",
      img: require("../media/batch2023/Vaishali1st.png"),
      fb: "https://www.facebook.com/profile.php?id=100009936368592",
      insta: "https://www.instagram.com/_vaishalibarua/",
      linkedin: "https://www.linkedin.com/in/vaishali-barua-492980198/",
      mail: "",
   },
   {
      name: "Sagar Agarwal",
      post: "Webmaster",
      img: require("../media/batch2023/Sagar1st.png"),
      fb: "https://www.facebook.com/sagar.agarwal.1612",
      insta: "https://www.instagram.com/sagar_agarwal05/",
      linkedin: "https://www.linkedin.com/in/sagar-wal/",
      mail: "",
   },
   {
      name: "Chetan Sheoran",
      post: "Convenor",
      img: require("../media/batch2023/chetan.png"),
      fb: "",
      insta: "http://www.instagram.com/da_neutrino/",
      linkedin: "",
      mail: "chetan.sheoran.09.08.2001@gmail.com",
   },
   {
      name: "Aditi Sengupta",
      post: "Robo-Society Head",
      img: require("../media/batch2023/aditi.png"),
      fb: "https://www.facebook.com/aditi.sengupta.589",
      insta: "https://www.instagram.com/lost_by_the_rustic_roads/",
      linkedin: "https://www.linkedin.com/in/aditi-sengupta-8417a2179/",
      mail: "",
   },
   {
      name: "Ishaun Sinha",
      post: "Soceity and Section Secretary",
      img: require("../media/batch2023/Ishaun_Sinha.png"),
      fb: "https://www.facebook.com/profile.php?id=100009541036392",
      insta: "https://www.instagram.com/sinha_ishaan?utm_medium=copy_link",
      linkedin: "https://www.linkedin.com/in/ishaan-sinha-8258b31a8",
      mail: "sinhaishaan46@gmail.com",
   },
   // {
   //    name: "Abhishek Basu",
   //    post: "Senior Executive Member",
   //    img: require("../media/batch2023/Abhishek1.png"),
   //    fb: "https://www.facebook.com/AbhishekBasuIsAwesome",
   //    insta: "https://www.instagram.com/iabhishekbasu/",
   //    linkedin: "https://www.linkedin.com/in/iabhishekbasu",
   //    mail: "iabhishekbasu@ieee.org",
   // },   
   // {
   //    name: "Arka Seth",
   //    post: "Senior Executive Member",
   //    img: require("../media/batch2023/arka.png"),
   //    fb: "https://facebook.com/arka.seth.5/",
   //    insta: "https://instagram.com/arka2000",
   //    linkedin: "https://linkedin.com/in/arkaseth",
   //    mail: "",
   // },
   // {
   //    name: "Bishwajit Ghosh",
   //    post: "Senior Executive Member",
   //    img: require("../media/batch2023/Bishwajit.jpeg"),
   //    fb: "",
   //    insta: "",
   //    linkedin: "https://www.linkedin.com/in/bishwajit-ghosh-6a896b1a3/",
   //    mail: "",
   // },


   
   // {
   //    name: "Disha Sharma",
   //    post: "Senior Executive Member",
   //    img: require("../media/batch2023/disha.png"),
   //    fb: "https://www.facebook.com/lowkeydisha",
   //    insta: "https://www.instagram.com/deesuhcreates/",
   //    linkedin: "https://www.linkedin.com/in/dishasharma2002/",
   //    mail: "",
   // },
   // {
   //    name: "Sayan Mondal",
   //    post: "Senior Executive Member",
   //    img: require("../media/batch2023/Sayan.png"),
   //    fb: "https://www.facebook.com/akathesayan/",
   //    insta: "https://www.instagram.com/akathesayan/",
   //    linkedin: "https://www.linkedin.com/in/sa-y-an/",
   //    mail: "",
   // },
];

export default Batch2023;
